import React, { Suspense } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Adminwrapper from "./pages/Admin/Adminwrapper";
// import AdminLogin from "./pages/Admin/AdminLogin/AdminLogin";
// import Dashboard from "./pages/Admin/Dashboard/Dashboard";
// import Protected from "./pages/Admin/ProtectedRoute";
// import AdminMessages from "./pages/Admin/Dashboard/Messages/AdminMessages";
// import AdminServices from "./pages/Admin/Dashboard/Services/AdminServices";
// import AdminProjects from "./pages/Admin/Dashboard/Projects/AdminProjects";
// import { preloadImageArray } from "./assets/images";
const Home = React.lazy(() => import("./pages/Web/Home/Home"));
const Webwrapper = React.lazy(() => import("./pages/Web/Webwrapper"));
const About = React.lazy(() => import("./pages/Web/About/About"));
const Services = React.lazy(() => import("./pages/Web/Services/Services"));
const Projects = React.lazy(() => import("./pages/Web/Projects/Projects"));
const Policywrapper = React.lazy(() =>
  import("./pages/Web/Policies/Policywrapper")
);
const Hse = React.lazy(() => import("./pages/Web/Policies/HSE/Hse"));
const Quality = React.lazy(() =>
  import("./pages/Web/Policies/Quality/Quality")
);
const Contact = React.lazy(() => import("./pages/Web/Contact/Contact"));
const Errorpage = React.lazy(() => import("./pages/Errorpage/Errorpage"));
const Loadingcomponent = React.lazy(() =>
  import("./pages/Admin/Loadingcomponent/Loadingcomponent")
);
function App() {
  // const [isSignedIn, setIsSignedIn] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const cacheAll = async () => {
  // await Promise.all(
  // preloadImageArray.map(async (image) => {
  //   return await new Promise(function (resolve, reject) {
  //     const img = new Image();
  //     img.src = `${window.location.origin}${image}`;
  //     img.onload = resolve();
  //     img.onerror = reject();
  //   });
  // })
  // );
  // window.addEventListener("load", () => {
  //   setLoading(false);
  // });
  // setLoading(false);
  // };
  // useEffect(() => {
  //   cacheAll();
  // }, []);
  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <Errorpage />,
      element: <Webwrapper />,
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "services",
          element: <Services />,
        },
        {
          path: "projects",
          element: <Projects />,
        },
        {
          path: "policies",
          element: <Policywrapper />,
          children: [
            {
              path: "hse",
              element: <Hse />,
            },
            {
              path: "quality",
              element: <Quality />,
            },
          ],
        },
        {
          path: "contact",
          element: <Contact />,
        },
      ],
    },
    // {
    //   path: "admin",
    //   element: <Adminwrapper />,
    //   children: [
    //     { path: "", element: <AdminLogin setIsSignedIn={setIsSignedIn} /> },
    //     {
    //       path: "dashboard",
    //       element: (
    //         <Protected isSignedIn={isSignedIn}>
    //           <Dashboard />
    //         </Protected>
    //       ),
    //       children: [
    //         { path: "messages", element: <AdminMessages /> },
    //         { path: "projects", element: <AdminProjects /> },
    //         { path: "services", element: <AdminServices /> },
    //       ],
    //     },
    //   ],
    // },
  ]);
  // if (loading) {
  //   return <Loadingcomponent />;
  // } else {
  return (
    <Suspense
      fallback={
        <Loadingcomponent
          loadingtext={"loading..."}
          width="100vw"
          height="100vh"
        />
      }
    >
      <RouterProvider router={router} />
      <ToastContainer />
    </Suspense>
  );
}
// }
export default App;
